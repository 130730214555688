<template>
  <div :style="{ padding: '0in' }">
    <b-button @click="updateEvents">update events</b-button>

    <PleasantCalendar ref="calendar" :events="events" />
  </div>
</template>

<script>
/*
  

*/
import moment from 'moment'
import PleasantCalendar from '../index.vue'

export default {
  components: {
    PleasantCalendar
  },
  data() {
    return {
      /*
        [{ date, name }]
      */
      events: [
        {
          // date: new Date (),
          // date: moment(),
          date: `2022-01-21T05:00:00.000Z`,
          name: 'today'
        }
      ]
    }
  },
  methods: {
    updateEvents() {
      this.events = [
        {
          date: moment().add(1, 'days'),
          name: 'today'
        }
      ]
    }
  },
  mounted() {}
}
</script>
