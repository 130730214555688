<template>
  <div :style="{ padding: '0in' }">
    <PleasantCalendar ref="calendar" :events="events" />
  </div>
</template>

<script>
/*
  

*/
import PleasantCalendar from '../index.vue'

export default {
  components: {
    PleasantCalendar
  },
  data() {
    return {
      /*
        [{ date, name }]
      */
      events: [
        // {
        //   date: `2022-01-28T05:00:00.000Z`,
        //   name: 'another event'
        // },
        // {
        //   startDate: `2022-01-21T05:00:00.000Z`,
        //   endDate: `2022-01-22T05:00:00.000Z`,
        //   name: '21 @ 5am to 22 @ 5am'
        // },
        // {
        //   startDate: `2022-01-28T06:00:00.000Z`,
        //   endDate: `2022-01-28T09:00:00.000Z`,
        //   name: '28th, 6am to 9am'
        // },
        {
          startDate: `2022-01-12T00:00:00.000Z`,
          endDate: `2022-01-20T00:00:00.000Z`,
          name: 'An Event'
        }
      ]
    }
  },
  methods: {
    updateEvents() {
      // this.events = [
      //   {
      //     date: moment().add(1, 'days'),
      //     name: 'today'
      //   }
      // ]
    }
  },
  mounted() {}
}
</script>
