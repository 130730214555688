<template>
  <div :style="{ padding: '1in' }">
    <b-tabs v-model="activeTab" :destroy-on-hide="true">
      <b-tab-item label="Multi Day Events">
        <MultiDayEvents />
      </b-tab-item>

      <b-tab-item label="Example One">
        <One />
      </b-tab-item>
    </b-tabs>

    <br />
  </div>
</template>

<script>
/*
  
  

*/

import One from './1.vue'
import MultiDayEvents from './MultiDayEvents.vue'

export default {
  components: {
    One,
    MultiDayEvents
  },
  data() {
    return {
      activeTab: 0
    }
  }
}
</script>
